/* App.css */

/* Page loader */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dadada;
  /* pure white background */
  z-index: 10000;
  /* high z-index to make sure it's on top */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 8px solid #f3f3f3;
  /* reduced the border thickness for smaller loader */
  border-top: 8px solid #000000;
  /* classy black spinner */
  border-radius: 50%;
  width: 60px;
  /* reduced the size */
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* Initially hide the sidebar */
.sidebar {
  display: none;
}

/* Enable sidebar when screen is 1700px wide or more */
@media (min-width: 1700px) {
  .sidebar {
    display: block;
    position: fixed;
    top: 50%;
    left: 1%;
    transform: translateY(-50%);
    z-index: 1000;
  }
}

.sidebar a {
  display: flex;
  /* Align the icon and text side by side */
  align-items: center;
  /* Vertically center the content */
  margin-bottom: 10px;
  /* Reduced margin for more compact view */
  font-size: 1em;
  /* Slightly smaller text */
  color: #666;
  padding: 8px;
  /* Reduced padding */
  border-radius: 4px;
  transition: color 0.3s, transform 0.3s;
  /* Removed background-color transition */
}

.sidebar a:hover {
  color: #3273dc;
  transform: scale(1.05);
  /* Slightly enlarging on hover */
}

.sidebar a.is-active {
  color: #3273dc;
  transform: scale(1.1);
  /* Enlarged for active state */
}

/* Text transition for sidebar */
.sidebar a span {
  margin-left: 5px;
  /* Reduced margin for more compact view */
  opacity: 0;
  transform: translateX(-20%);
  /* Reduced the initial translation for smoother effect */
  transition: opacity 0.5s, transform 0.5s cubic-bezier(0.22, 1, 0.36, 1);
  white-space: nowrap;
}

.sidebar a:hover span,
.sidebar a.is-active span {
  opacity: 1;
  transform: translateX(0);
}



/* sections size */
.section {
  min-height: 1000px;
}

/*colors*/
/* General Styles */
body {
  background-color: #FAFAFA;
}


/* Custom Navbar Styles */
.custom-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background: #b3b2b2;
  transition: background-color 0.3s ease, opacity 0.3s ease;
  /* Smooth transition for color and opacity change */
  padding: 0 2%;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.custom-navbar.scrolled {
  background-color: rgba(194, 194, 194, 0.9);
  /* Slight transparency upon scrolling with a darker shade */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-logo {
  height: 40px;
  margin-right: 10px;
}

.navbar-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 2;
}

.navbar-item {
  font-size: 1.2em;
  font-weight: bold;
  padding: 0 20px;
  color: #000000;
  /* Text always black */
  text-decoration: none;
  transition: transform 0.2s;
  /* Removed color transition */
}

.navbar-item:hover {
  transform: scale(1.01);
  /* Slightly enlarging on hover */
  background-color: transparent !important;
  /* Removed background color on hover */
  color: #000000 !important;
  /* Text always black */
}

.navbar-brand:hover {
  transform: scale(1.01);
  /* Slightly enlarging on hover */
}

.navbar-item::before,
.navbar-item::after {
  content: '';
  height: 14px;
  width: 14px;
  position: absolute;
  transition: all .35s ease;
  opacity: 0;
}

.navbar-item::before {
  right: 0;
  top: 0;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  transform: translate(-100%, 50%);
}

.navbar-item::after {
  left: 0;
  bottom: 0;
  border-bottom: 2px solid #000;
  border-left: 2px solid #000;
  transform: translate(100%, -50%);
}


.navbar-item.active::before,
/* Adding active state for corner lines */
.navbar-item.active::after {
  transform: translate(0, 0);
  opacity: 1;
}


/* Responsive styles for smaller screens */
@media screen and (max-width: 768px) {
  .custom-navbar {
    height: 60px;
    align-items: center;
    padding: 0;
  }

  .navbar-item {
    font-size: 0.8em;
    padding: 5px 10px;
  }

  .navbar-logo {
    height: 30px;
  }
}


.cars-section {
  background-color: #EAEAEA;
  /* Light grey background */
  padding: 2rem;
  border-radius: 8px;
  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
}






/* Gradient background for hero */
.hero.is-fullheight-with-navbar {
  background: linear-gradient(to bottom, #dcdcdc, #EAEAEA);
  padding-top: 70px;
  /* Adjust for navbar height */
  position: relative;
}

/* Black color for the text */
.title,
.subtitle {
  color: #000000;
}

/* Specific styles for the title */
.title {
  font-weight: 700;
  /* Boldness/thickness of the title */
}

/* Specific styles for the subtitle */
.subtitle {
  font-weight: 500;
  /* Medium Boldness/thickness */
  max-width: 700px;
  /* Limit width to 700px; adjust as needed */
  margin-left: auto;
  margin-right: auto;
}

/* Position and clip the car image */
.hero-foot img {
  object-fit: cover;
  object-position: center top;
  height: auto;
  margin-top: 0%;
  /* Adjust based on how far down you want the image */
  margin-bottom: 0%;
  /* Adjust based on how much you want to cut off the image */
  position: relative;
  z-index: 1;
}

/* Black gradient filter over the car image */
.hero-foot::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  z-index: 0;
  pointer-events: none;
  /* This will ensure that the overlay does not interfere with clicks */
}

/* Adjust the position of hero on mobile */
.hero {
  margin-top: -100px;
  /* Adjust for navbar height */
}


.about-us-container .about-section {
  background-color: #EAEAEA;
  /* Light grey background */
  padding: 2rem;

}

.about-us-container .about-title {
  font-size: 2.2em;
  /* Reduced font size */
  margin-bottom: 2rem;
  /* Increased bottom margin */
  font-weight: bold;
  color: #000;
}

.about-us-container .about-text {
  font-size: 1.1em;
  /* Reduced font size */
  line-height: 1.6;
  margin-bottom: 2rem;
  /* Increased bottom margin */
  font-weight: 600;
  /* Increased font weight */
  color: #333;
}

.about-us-container .about-list {
  padding-left: 0;
  list-style-type: none;
  margin-top: 2rem;
  /* Increased space above the list */
}

.about-us-container .about-list-item {
  margin-bottom: 2rem;
  /* Increased bottom margin */
  font-size: 1em;
  /* Reduced font size */
  line-height: 1.5;
  font-weight: 500;
  /* Made text slightly bolder */
}

.about-us-container .about-list-item strong {
  margin-right: 0.5rem;
  font-weight: bold;
}

.about-us-container .fa-icon {
  margin-right: 0.5rem;
  font-size: 1.5em;
  color: #000;
}





.contact-us-container .contact-section {
  background-color: #EAEAEA;
  /* Light grey background */
  padding: 2rem;
  border-radius: 8px;
  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
}

.contact-us-container .contact-title {
  font-size: 2.5em;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #000;
}

.contact-us-container .contact-item span {
  font-size: 1.12em;
  /* Adjusted font size */
  font-weight: 500;
}


.contact-us-container .contact-form-description p {
  font-size: 1.25em;
  line-height: 1.6;
  margin-bottom: 1rem;
  font-weight: 500;
  color: #333;
}

.contact-us-container .contact-details {
  margin-bottom: 3rem;
  /* Increased bottom margin */
}

.contact-us-container .contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.1em;
  line-height: 1.5;
  text-decoration: none;
  color: #333;
}

.contact-us-container .fa-icon {
  margin-right: 0.5rem;
  font-size: 1.5em;
  color: #000;
}

.contact-us-container .contact-form {
  margin-top: 2rem;
  /* Added top margin */
}

.contact-us-container .form-title {
  font-size: 2em;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #000;
}

.contact-us-container .field {
  margin-bottom: 1.5rem;
}

.contact-us-container .label {
  font-size: 1.1em;
  margin-bottom: 0.5rem;
}

.contact-us-container .input,
.contact-us-container .textarea,
.contact-us-container .select {
  font-size: 1em;
  line-height: 1.5;
}

.contact-us-container .button {
  font-size: 1em;
  font-weight: bold;
}

.contact-us-container .contact-form {
  margin-top: 2rem;
  /* Added top margin */
  background-color: #ffffff;
  /* Set background color to white */
  padding: 1.5rem;
  /* Added padding */
  border-radius: 8px;
  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
  max-width: 800px;
  /* Maximum width */

}

.contact-us-container .input,
.contact-us-container .textarea,
.contact-us-container .select {
  font-size: 1em;
  line-height: 1.5;
  padding: 0.5rem;
  /* Added padding */
}

/* Additional styles for better visibility */
.contact-us-container .button {
  background-color: #4a90e2;
  /* Adjusted button color */
  color: #ffffff;
  /* White text color */
}

.contact-us-container .button:hover {
  background-color: #3a7bd5;
  /* Slightly darker on hover */
}




/* Footer Styles */
.footer {
  background-color: #222222;
  padding: 2rem 0;
  text-align: center;
}

.footer p,
.footer a,
.footer li,
.footer-text {
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
}

.footer p {
  margin: 0;
}

.footer a {
  text-decoration: none;
  transition: color 0.3s;
  color: #ffffff;
  /* Changed color for clickable elements */
}

.footer a:hover {
  color: #9bc4df;
}

.footer-text {
  font-size: 0.9rem;
  /* Smaller text size for copyright */
}

.logo-container {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .footer .column {
    text-align: center !important;
  }

  .footer .column.is-one-third {
    margin-bottom: 1rem;
  }

  .logo-container {
    justify-content: center;
    margin-bottom: -3rem !important;
    /* Reduce space below logo */
  }

  .footer .fas {
    display: block;
    margin-bottom: 0.5rem;
  }
}

.fa-icon {
  font-size: 1.3em; /* Increase the size of the icons */
  margin-right: 1rem;
}